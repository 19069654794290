<!--sidebar-->
<c-sidebar #sidebar="cSidebar" class="d-print-none sidebar sidebar-fixed" id="sidebar" visible>
  <c-sidebar-brand class="logo-brand" [brandFull]="{
    src: 'assets/images/logo.png',
    width: 90,
    height: 46,
    alt: 'TrackOn Logo'
  }" [brandNarrow]="{
    src: 'assets/images/logo.png',
    width: 46,
    height: 46,
    alt: 'TrackOn Logo'
  }" routerLink="./" />
  <ng-scrollbar pointerEventsMethod="scrollbar">
    <c-sidebar-nav [navItems]="items" dropdownMode="close" />
  </ng-scrollbar>
  <c-sidebar-toggler *ngIf="!sidebar.narrow" toggle="unfoldable" cSidebarToggle="sidebar" />
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <!--app-header-->
  <app-default-header class="mb-4 d-print-none header header-sticky" position="sticky" sidebarId="sidebar" />
  <!--app-body-->
  <div class="body flex-grow-1 px-3">
    <c-container breakpoint="lg" class="h-auto">
      <router-outlet />
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer />
</div>