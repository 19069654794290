import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

@Injectable()
export class SocketService {
    constructor(private socket: Socket) { }

    sendMessage(userId: string) {
        const payload = { userId };
        this.socket.emit('send_file', payload);
    }

    getMessage() {
        return this.socket.fromEvent('progress').pipe(map((data: any) => data));
    }
}