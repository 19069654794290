import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        public authService: AuthService,
        public router: Router,
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {
        if (this.authService.isLoggedIn !== true) {
            this.router.navigate(['auth/login'], { queryParams: { retUrl: state.url } });
        }

        const jwtToken = this.authService.userLogin;
        const decodedToken: any =
            this.authService.userLogin != null
                ? jwt_decode(jwtToken as string)
                : null;
        const userRole = decodedToken != null ? decodedToken.role : null;
        const userStatus = decodedToken != null ? decodedToken.status : null;

        if (!jwtToken) {
            this.router.navigate(['auth/login'], { queryParams: { retUrl: state.url } });
        } else {
            this.checkUserLogin(next, state, userRole, userStatus);
        }

        return true;
    }

    checkUserLogin(next: ActivatedRouteSnapshot, state: any, userRole: any, userStatus: boolean): boolean {
        if (userStatus) {
            if (next.data['role'] && next.data['role'].indexOf(userRole) === -1) {
                // Check if the user's role is not granted access
                this.router.navigate(['/dashboard'], {
                    queryParams: { returnUrl: state.url },
                });
                return false;
            } else {
                return true;
            }
        }
        if (state.url != '/dashboard') {
            this.router.navigate(['/dashboard']);
        }
        return false;
    }

    isTokenExpired(token: string) {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return expiry * 1000 > Date.now();
    }
}

@Injectable({
    providedIn: 'root',
})
export class LoginGuard {
    constructor(public authService: AuthService, public router: Router) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {
        if (this.authService.isLoggedIn === true) {
            this.router.navigate(['dasboard']);
        }
        return true;
    }
}