
import { Injectable, NgZone } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, from, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService {

    public baseUrl;

    constructor(
        public router: Router,
        public ngZone: NgZone,
        private httpClient: HttpClient
    ) {
        this.baseUrl = environment.apiUrl;
    }
    // Sign in with email/password
    signIn(email: string, password: string): Observable<any> {
        return this.httpClient.post(`${this.baseUrl}/auth/login`, { email, password }).pipe(tap((resp: any) => {
            if (resp.code != null) {
                if (resp.code == 'ok') {
                    this.setToken(resp.data.token);
                    this.router.navigate(['dashboard']);
                }
            }
            return from(Promise.resolve(resp));
        }));
    }

    // Sign up with email/password
    signUp(email: string, password: string, identification_number: string, identification_type: string, business_name: string): Observable<any> {
        return this.httpClient.post(`${this.baseUrl}/auth/signup`, {
            email, password, identification_number, identification_type, business_name,
        });
    }

    // Sign in with email/password
    changePassword(token: string, password: string): Observable<any> {
        return this.httpClient.post(`${this.baseUrl}/auth/change_password`, { token, password });
    }

    // Forgot password
    forgotPassword(email: string): Observable<any> {
        return this.httpClient.post(`${this.baseUrl}/auth/forgot_password`, { email });
    }

    ///Reset password
    resetPassword(token: string, password: string): Observable<any> {
        return this.httpClient.post(`${this.baseUrl}/auth/reset_password`, { token, password });
    }

    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('token')!);
        return user !== null ? true : false;
    }

    get userLogin(): any {
        return JSON.parse(localStorage.getItem('token')!);
    }

    // Sign out
    async signOut() {
        localStorage.removeItem('token');
        this.router.navigate(['auth/login']);
    }

    setToken(token: string) {
        localStorage.setItem('token', JSON.stringify(token)!);
        JSON.parse(localStorage.getItem('token')!);
    }

} 