import { Component, Input } from '@angular/core';
import { HeaderComponent } from '@coreui/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import jwt_decode from 'jwt-decode';
import { SocketService } from 'src/app/services/socket/socket.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.scss'],
})
export class DefaultHeaderComponent extends HeaderComponent {

  @Input() sidebarId: string = "sidebar";

  public newMessages = new Array(4)
  public newTasks = new Array(5)
  public newNotifications = new Array(5)
  user: any | undefined;
  progress: number | undefined;

  constructor(
    private authService: AuthService,
    private socketService: SocketService,
    private toastr: ToastrService,
  ) {
    super();
  }

  ngOnInit() {
    const jwtToken = this.authService.userLogin;
    const decodedToken: any =
      this.authService.userLogin != null
        ? jwt_decode(jwtToken as string)
        : null;
    this.user = decodedToken != null ? decodedToken : null;

    this.socketService.getMessage().subscribe((data: { current: number, total: number }) => {
      this.progress = Math.round((data.current / data.total) * 100);
      console.log(this.progress);
      if (data.current === data.total) {
        this.toastr.success('Maestro procesado con éxito!!', 'Procesado');
        this.progress = undefined;
      }
    });

  }

  signOut() {
    this.authService.signOut();
  }
}
