<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <button toggle="visible" cHeaderToggler [cSidebarToggle]="sidebarId" class="ps-1">
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>
    <c-header-nav class="d-none d-lg-flex me-auto">
      <c-container [fluid]="true" style="margin-top: -25px;">
        <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
      </c-container>
    </c-header-nav>
    <div *ngIf="progress !== undefined" class="progress-file">
      <span>Procesando archivo</span>
      <br>
      <c-progress class="mb-3">
        <c-progress-bar [value]="progress">{{progress}}%</c-progress-bar>
      </c-progress>
    </div>
    <c-header-nav class="d-none d-lg-flex">
      <c-nav-item>
        <a routerLink="./" cNavLink>
          <svg cIcon name="cilBell" size="lg"></svg>
        </a>
      </c-nav-item>
    </c-header-nav>
    <c-header-nav class="ms-3">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>

  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button cButton color="" [caret]="false" cDropdownToggle class="py-0">
      <c-avatar cTextColor="primary" shape="rounded-1" size="md"
        src="{{ user['photo_url'] != null ? user['photo_url'] : 'assets/img/avatars/avatar.avif' }}" status="success">
      </c-avatar>
      <span>
        {{user['name'] != null ? user['name'] : user['email']}}
      </span>
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto list">
      <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Account</h6>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilUser"></svg>
          Profile
        </a>
      </li>
      <li>
        <hr cDropdownDivider />
      </li>
      <li>
        <a routerLink="./" cDropdownItem (click)="signOut()">
          <svg cIcon class="me-2" name="cilLockLocked"></svg>
          Salir
        </a>
      </li>
      <li></li>
    </ul>
  </c-dropdown>
</ng-template>