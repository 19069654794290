import { Component } from '@angular/core';

import { navItems } from './_nav';
import { INavData } from '@coreui/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import jwt_decode from 'jwt-decode';
import { freeSet } from '@coreui/icons';

export interface MyINavData extends INavData {
  role?: string[];
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent {
  items: MyINavData[];
  public navItems = navItems;

  constructor(private authService: AuthService) {
    this.items = this.getSidebarItems();
  }

  private getSidebarItems(): MyINavData[] {
    const jwtToken = this.authService.userLogin;
    const decodedToken: any =
      this.authService.userLogin != null
        ? jwt_decode(jwtToken as string)
        : null;
    const userRole = decodedToken != null ? decodedToken.role : null;

    let navItems: MyINavData[] = new Array<MyINavData>();
    this.navItems.forEach((item: any) => {
      if (item.role != null) {
        if (item.role.indexOf(userRole) !== -1) {
          navItems.push(item);
        }
      } else {
        navItems.push(item);
      }
    });
    return navItems;
  }
}
