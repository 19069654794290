import { MyINavData } from './default-layout.component';

export const navItems: MyINavData[] = [
  {
    name: 'Panel Principal',
    url: '/dashboard',
    iconComponent: { name: 'cil-speedometer' },
  },
  {
    name: 'Usuarios',
    url: '/users',
    iconComponent: { name: 'cil-people' },
    role: ['SuperAdmin', 'AdminCliente']
  },
  {
    name: 'Ciclos de Inventarios',
    url: '/shops',
    iconComponent: { name: 'cil-cart' },
    role: ['SuperAdmin', 'AdminCliente', 'LiderInventario']
  },
  {
    name: 'Maestros',
    url: '/masters',
    iconComponent: { name: 'cil-settings' },
    role: ['SuperAdmin', 'AdminCliente', 'LiderInventario']
  },
  {
    name: 'Inventarios',
    url: '/inventories',
    iconComponent: { name: 'cil-storage' },
    role: ['SuperAdmin', 'AdminCliente', 'LiderInventario'],
  },
];
