import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultLayoutComponent } from './containers';
import { Page404Component } from './views/pages/page404/page404.component';
import { Page500Component } from './views/pages/page500/page500.component';
import { AuthGuard, LoginGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./views/pages/pages.module').then((m) => m.PagesModule)
      },
      {
        path: 'users',
        canActivate: [AuthGuard],
        data: {
          role: ['SuperAdmin', 'AdminCliente'],
        },
        loadChildren: () =>
          import('./views/users/users.module').then((m) => m.UsersModule)
      },
      {
        path: 'shops',
        canActivate: [AuthGuard],
        data: {
          role: ['SuperAdmin', 'AdminCliente', 'LiderInventario'],
        },
        loadChildren: () =>
          import('./views/shops/shops.module').then((m) => m.ShopsModule)
      },
      {
        path: 'masters',
        canActivate: [AuthGuard],
        data: {
          role: ['SuperAdmin', 'AdminCliente', 'LiderInventario'],
        },
        loadChildren: () =>
          import('./views/masters/masters.module').then((m) => m.MastersModule)
      },
      {
        path: 'inventories',
        canActivate: [AuthGuard],
        data: {
          role: ['SuperAdmin', 'AdminCliente', 'LiderInventario'],
        },
        loadChildren: () =>
          import('./views/inventories/inventories.module').then((m) => m.InventoriesModule)
      },
    ]
  },
  {
    path: '404',
    component: Page404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: Page500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'auth',
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./views/auth/auth.module').then((m) => m.AuthModule)
  },
  { path: '**', redirectTo: 'dashboard' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
